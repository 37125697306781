.footerInfo {
  margin-top: 40px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-top: 1px solid #F5F5F5;
  border-bottom: 1px solid #F5F5F5;
}
.footer-info-item {
  display: flex;
  align-items: center;
  margin-bottom: 3px;
}
.footer-info-item-ul {
  width: calc(100% - 80px);
  overflow: hidden;
  height: 24px;
}
.footer-info-item:last-child {
  margin-bottom: 0px;
}
.footer-info-item .footerTitle {
  font-size: 12px;
  font-weight: 400;
  color: rgba(38,38,38,0.4000);
  margin-right: 20px;
  display: inline-block;
  min-width: 60px;
  /* text-align: right; */
}
.footer-info-item .title-item .click-report-log {
  font-size: 12px;
  font-weight: 400;
  color: rgba(38,38,38,0.7200);
}
.footer-info-item .title-item .click-report-log:hover {
  color: #00BDFF;
}
/* .footer-container dl {
  min-width: 202px !important;
}
.footer-container dl dd a  {
  color: rgba(38, 38, 38, 0.72);
}
.footer-container .footer-bottom {
  margin-top: 20px !important;
}
.footer-container .wechat-pic {
  background: url(https://juheimg.oss-cn-hangzhou.aliyuncs.com/www/v6/headerFooter/wechat-white.png) center center no-repeat;
}

.footer-list dl{ 
  float: left;
  margin-right: 80px !important;
}
.footer-list dl dt{
  font-size: 14px;
  line-height: 14px;
  font-weight: 700;
  color: #262626;
}
.footer-list dl dd a{
  color: #262626;
} */



.footer {
  width: 100%;
  min-width: 1200px;
  border-top: 1px solid rgba(102, 102, 102, 0.1);
  color: #666;
}
.footer-container {
  width: 1200px;
  margin: 0 auto;
  padding-top: 40px;
  padding-bottom: 20px;
}
.footer-container .footer-link {
  float: left;
}
.footer-container .footer-list  dl {
  float: left;
  margin-right: 80px;
}
.footer-container dl dt {
  font-size: 14px;
  line-height: 14px;
  font-weight: 700;
  color: #262626;
  margin-bottom: 3px;
  position: relative;
}
.footer-container dl dd {
  margin-top: 20px;
  font-size: 12px;
  line-height: 12px;
  color: rgba(38, 38, 38, 0.72);
  cursor: default;
}
.footer-container dl dd a:hover {
  color: #00BDFF !important;
}
.footer-container dl dd a {
  color: #666;
  cursor: pointer;
}
.footer-container .footer-wechat {
  float: right;

}
.footer-container .footer-wechat .wechat-container {
  display: flex;
  align-items: center;
  width: 216px;
  padding: 12px;
  margin-bottom: 24px;
  background: #F5F5F5;
  border-radius: 4px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
.footer-container .footer-wechat .wechat-container .pic{
  width: 60px;
  height: 60px;
  margin-right: 12px;
}
.footer-container .footer-wechat .wechat-title {
  font-size: 14px;
  font-weight: 500;
  color: #262626;
  display: block;
}
.footer-container .footer-wechat .wechat-text {
  font-size: 12px;
  font-weight: 400;
  color: #888888;
  display: block;
  margin-top: 10px;
}
.footer-container .footer-wechat .footer-contact .consult-title {
  font-size: 12px;
  line-height: 12px;
  font-weight: 400;
  color: #262626;
}
.footer-container .footer-wechat .footer-contact .consult-title .consult-tel {
  font-weight: 600;
  font-size: 16px;
}
.footer-container .footer-wechat .footer-contact .footer-contact-btn{
  display: block;
  width: 216px;
  height: 40px;
  margin-top: 16px;
  font-size: 12px;
  text-align: center;
  line-height: 40px;
  color: #fff;
  background: #00BDFF;
  border-radius: 4px;
}
.footer-container .wechat-name {
  font-size: 12px;
  opacity: .5;
}
.footer-container .wechat-pic {
  margin-top: 30px;
  width: 110px;
  height: 110px;
  /* background: url(https://juheimg.oss-cn-hangzhou.aliyuncs.com/www/v6/headerFooter/wechat-white.png) center center no-repeat; */
}
.footer-container .footer-bottom {
  margin-top: 38px;
  /* padding-left: 15px; */
  font-size: 12px;
}
.footer-container .footer-bottom .footer-bottom-left {
  float: left;
}
.footer-container .footer-bottom .footer-bottom-right {
  float: right;
}
.footer-container .footer-bottom a {
  display: inline-block;
  color: #666;
}
.footer-container .footer-bottom span {
  vertical-align: middle;
  opacity: .3;
  margin-right: 3px;
}
.footer-container .footer-bottom .footer-pic1 {
  display: inline-block;
  width: 18px;
  height: 20px;
  background: url(https://juheimg.oss-cn-hangzhou.aliyuncs.com/www/v6/headerFooter/bei2.png) no-repeat;
  vertical-align: middle;
  opacity: 1;
}
.footer-container .footer-bottom .footer-pic2 {
  display: inline-block;
  width: 18px;
  height: 20px;
  background: url(https://juheimg.oss-cn-hangzhou.aliyuncs.com/www/v6/headerFooter/bei1.png) no-repeat;
  vertical-align: middle;
  opacity: 1;
}
.footer-container .footer-bottom .footer-license {
  margin-left: 15px;
}
.footer-container .footer-bottom .footer-icp {
  margin-left: 15px;
}
.footer-help {
  position: fixed;
  width: 180px;
  height: 46px;
  bottom: 30px;
  right: 30px;
  font-size: 16px;
  z-index: 990;
}
.footer-help-btn {
  width: 180px;
  height: 46px;
  line-height: 42px;
  text-align: center;
  background: #00bdff;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
}
.footer-help-btn .iconfont {
  font-size: 24px;
  vertical-align: middle;
}
.footer-help-btn span {
  vertical-align: middle;
}
.footer-help-btn .dot {
  display: inline-block;
  width: 4px;
  height: 4px;
  background: #fff;
  border-radius: 50%;
}
.footer-help-top {
  display: none;
  position: absolute;
  left: -66px;
  top: 0;
  width: 46px;
  height: 46px;
  line-height: 46px;
  text-align: center;
  background: rgba(108, 108, 108, 0.3);
  border-radius: 4px;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
}
.footer-help-top span {
  font-size: 14px;
}
.footer-help-container {
  display: none;
  position: absolute;
  bottom: 60px;
  left: 0px;
  width: 197px;
  height: 280px;
  /* background: url(https://juheimg.oss-cn-hangzhou.aliyuncs.com/www/v6/headerFooter/help-container.png) no-repeat; */
  width: 174px;
  height: 318px;
  background: #FFFFFF;
  box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.06);
  border-radius: 12px;
}
.footer-help-container:after{
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  right: 80px;
  bottom: -12px;
  border-left: 2px solid #f5f5f5;
  border-bottom: 2px solid #f5f5f5;
  transform: translateY(-50%) rotate(-45deg);
  background: #fff;
}
.footer-help-container .help-list {
  padding: 20px 0 0 25px;
}
/* .footer-help-container .help-list .help-icon.sdk-help-icon {
  background: url('../../../images/v6/headerFooter/sdk-icon.png?v=1.1') left top 2px no-repeat;
  width: 20px;
  height: 20px;
} */
.footer-help-container .help-list li {
  height: 40px;
  margin-bottom: 20px;
}
.footer-help-container .help-list a {
  color: #666;
  display: block;
}
.footer-help-container .help-list .help-icon {
  float: left;
}
.footer-help-container .help-list .help-icon .iconfont {
  font-size: 22px;
}
.footer-help-container .help-list .help-text {
  float: left;
  margin-left: 10px;
}
.footer-help-container .help-list .help-title {
  font-weight: bold;
}
.footer-help-container .help-list .help-phone {
  margin-top: 5px;
  font-size: 12px;
  font-weight: bold;
  color: #00bdff;
}
.footer-help-container .help-list .help-desc {
  margin-top: 2px;
  font-size: 12px;
  color: #bbb;
}
